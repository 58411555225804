import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '../tools/withStyles'
import { Secuence } from '../components/Secuence'
import { Brand } from '../components/Brand'
import { Menu } from '../components/Menu'

import { SocialLinks } from '../components/SocialLinks'
import { Legal } from '../components/Legal'
const styles = (theme) => {
  return {
    root: {
      margin: 'auto',
      width: '100%',
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
      margin: [0, 'auto'],
      padding: 20,
    },
    kuswap: {
      display: 'flex',
      flexDirection: 'column',
      margin: [0, 'auto'],
      width: '100%',
      height: '100%',
    },
    brand: {
      margin: [0, 'auto', 30],
      padding: [10, 0],
      width: '100%',
      maxWidth: 700,
    },
    menu: {
      margin: [0, 'auto', 20],
      width: '100%',
      maxWidth: 600,
    },
    social: {
      margin: [0, 'auto'],
      width: '100%',
      maxWidth: 400,
    },
    legal: {
      position: 'absolute',
      left: '50%',
      bottom: 0,
      transform: 'translateX(-50%)',
    },
  }
}

class Component extends React.Component {
  state = { width: 0, height: 0 }

  componentDidMount() {
    this.updateWindowDimensions()
    window.addEventListener('resize', this.updateWindowDimensions)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions)
  }
  updateWindowDimensions = () => {
    this.setState({ width: window.innerWidth, height: window.innerHeight })
  }

  onLinkStart = (event, { isInternal }) => {
    if (isInternal) {
      // @ts-ignore
      this.secuenceElement.exit()
    }
  }

  render() {
    // @ts-ignore
    const { classes } = this.props
    const isMobileDisableIframe = this.state.width
      ? this.state.width < 836
      : false

    return (
      // @ts-ignore
      <Secuence ref={(ref) => (this.secuenceElement = ref)}>
        <div className={classes.root} style={{ height: '100%' }}>
          <div
            style={
              !isMobileDisableIframe
                ? {
                    display: 'flex',
                    justifyContent: 'space-between',
                    flexDirection: 'row',
                    height: '100%',
                  }
                : {}
            }
          >
            {/*//!  og */}
            <div className={classes.root}>
              <div className={classes.content}>
                {/*   @ts-ignore */}
                <Brand
                  className={classes.brand}
                  onLinkStart={this.onLinkStart}
                />

                <Menu
                  // @ts-ignore
                  className={classes.menu}
                  animation={{ duration: { enter: 400 } }}
                  scheme="expand"
                  onLinkStart={this.onLinkStart}
                />
                <SocialLinks
                  // @ts-ignore
                  className={classes.social}
                  onLinkStart={this.onLinkStart}
                />
              </div>
            </div>
          </div>
        </div>
      </Secuence>
    )
  }
}

// @ts-ignore
Component.propTypes = {
  classes: PropTypes.any.isRequired,
}

export default withStyles(styles)(Component)
